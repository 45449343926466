<script>
import { formatSequence } from '../util'

export default {
	props: {
		img: String,
		label: String,
		rawGraduations: { type: Object, required: true },
		excludedCylinders: Array,
	},
	data() {
		return { graduation: null, cylinder: null, axis: null }
	},
	computed: {
		graduations() {
			const graduations = Object.keys(this.rawGraduations)
			return formatSequence([
				...graduations.filter((n) => n <= 0).sort((a, b) => b - a),
				...graduations.filter((n) => n > 0).sort((a, b) => a - b),
			])
		},
		cylinders() {
			let cylinders = Object.keys(this.rawGraduations[this.graduation.value]).map((v) => Number(v))
			if (this.excludedCylinders)
				cylinders = cylinders.filter((cil) => !this.excludedCylinders.includes(cil))

			return formatSequence([
				...cylinders.filter((n) => n <= 0).sort((a, b) => b - a),
				...cylinders.filter((n) => n > 0).sort((a, b) => a - b),
			])
		},
		axisItems() {
			const axis = this.rawGraduations[this.graduation.value][this.cylinder.value]
			let res

			if (typeof axis === 'string') {
				const [outerKey, innerKey] = axis.split('_')
				res = [...this.rawGraduations[outerKey][innerKey]]
			} else {
				res = axis
			}

			return formatSequence(
				res.sort((a, b) => a - b),
				'degrees'
			)
		},
		showCylinder() {
			return this.graduation
		},
		showAxis() {
			return this.cylinder
		},
	},
	watch: {
		graduation(val) {
			this.$emit('update:graduation', val)
		},
		cylinder(val) {
			this.$emit('update:cylinder', val)
		},
		axis(val) {
			this.$emit('update:axis', val)
		},
	},
}
</script>

<template>
	<GraduationSelector-Wrapper :img="img">
		<GraduationSelector-Select
			:items="graduations"
			v-model="graduation"
			:title="`Graduación Ojo ${label}`"
		/>
		<GraduationSelector-Select
			v-if="showCylinder"
			:items="cylinders"
			v-model="cylinder"
			:title="`Cilindro Ojo ${label}`"
		/>
		<GraduationSelector-Select
			v-if="showAxis"
			:items="axisItems"
			v-model="axis"
			:title="`Eje Ojo ${label}`"
		/>
	</GraduationSelector-Wrapper>
</template>

