<script>
import Base from './comp_GraduationSelectorComplex.vue'
import { getGraduationSequence, getGraduationDegrees } from '../util'

export default {
	extends: Base,
	props: {
		img: String,
		label: String,
		rawGraduations: Array,
	},
	data() {
		return { graduation: null, cylinder: null, axis: null }
	},
	computed: {
		graduations() {
			return this.rawGraduations
		},
		cylinders() {
			return getGraduationSequence(-0.75, -2.25, 0.5)
		},
		axisItems() {
			return getGraduationDegrees(10, 180)
		},
		showCylinder() {
			return true
		},
		showAxis() {
			return true
		},
	},
}
</script>
