export const formatter = Intl.NumberFormat('es-AR', {
	minimumFractionDigits: 2,
	signDisplay: 'exceptZero',
})

function sortSequence(sequence, order = 'asc') {
	if (order === 'asc') return sequence.sort()
	return sequence.sort((a, b) => b - a)
}

export function generateSequence(start, end, increment = 0.25) {
	const sequence = []
	if (start < end) {
		for (let i = start; i <= end; i += increment) {
			sequence.push(i)
		}
	} else {
		for (let i = start; i >= end; i -= increment) {
			sequence.push(i)
		}
	}
	return sequence
}

export function formatSequence(sequence, type) {
	const formatFunction =
		type === 'degrees'
			? (s) => ({ text: s + '°', value: s })
			: (s) => ({ text: formatter.format(s), value: s })
	return sequence.map(formatFunction)
}

export function getGraduationSequence(start, end, increment = 0.25) {
	return formatSequence(generateSequence(start, end, increment), 'sign')
}

export function getGraduationDegrees(start, end, increment = 10) {
	return formatSequence(generateSequence(start, end, increment), 'degrees')
}

/**
 * Sorts an array by absolute value
 * @param {Array<{text: string, value: number}>} graduations
 * @returns {Array<{text: string, value: number}>}
 */
export function orderByAbsoluteValue(graduations) {
	return graduations.sort((a, b) => Math.abs(a.value) - Math.abs(b.value))
}

export function withCache(func) {
	const cache = new Map()

	return function (...args) {
		const key = args.join(',')

		if (cache.has(key)) return cache.get(key)

		const result = func(...args)
		cache.set(key, result)
		return result
	}
}
