<script>
import BaseProductPage from '#/v-shop-base-layout/vue-src/v-shop/product/view_ProductPage.vue'

export default {
	extends: BaseProductPage,
	lang: 'shop',
	computed: {
		hasCustomization() {
			return this.product.info.customizationKey?.indexOf('graduationSelector') === 0
		},
	},
	created() {
		if (this.hasCustomization) {
			this.colSizes.left = 6
			this.colSizes.right = 6
		}
	},
	// resolveData: BaseProductPage.resolveData,
}
</script>

