<script>
export default {
	props: {
		title: String,
		modelValue: Object,
		items: Array,
		placeholder: {
			type: String,
			default: 'Seleccione graduación',
		},
		returnObject: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		selected: {
			get() {
				return this.modelValue
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<template>
	<div class="mb-2">
		<div class="mb-1 font-2 font-weight-bold"><span class="cta--text">*</span> {{ title }}</div>
		<Select
			size="sm"
			v-model="selected"
			:items="items"
			:placeholder="placeholder"
			class="select"
			outlined
			:return-object="returnObject"
			hide-details
		/>
	</div>
</template>

<style lang="scss" scoped>
.select {
	border: 1px solid #eee;
}
</style>
