<script>
export default {
	props: {
		img: String,
	},
}
</script>

<template>
	<v-card max-width="350px" class="pa-2 w100">
		<div class="text-center">
			<img :src="img" />
		</div>
		<slot></slot>
	</v-card>
</template>
