<script>
import Base from './comp_GraduationSelectorComplex.vue'
import { formatSequence } from '../util'

export default {
	extends: Base,
	data() {
		return { graduation: null, cylinder: null }
	},
	computed: {
		cylinders() {
			const cylinders = this.rawGraduations[this.graduation.value]

			return formatSequence([
				...cylinders.filter((n) => n <= 0).sort((a, b) => b - a),
				...cylinders.filter((n) => n > 0).sort((a, b) => a - b),
			])
		},
		showAxis() {
			return false
		},
	},
}
</script>

