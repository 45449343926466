<script>
export default {
	props: {
		img: String,
		label: String,
		graduationRange: Array,
	},
	data() {
		return { graduation: null, add: null }
	},
	watch: {
		graduation(val) {
			this.$emit('update:graduation', val)
		},
		add(val) {
			this.$emit('update:add', val)
		},
	},
}
</script>

<template>
	<GraduationSelector-Wrapper :img="img">
		<GraduationSelector-Select
			:items="graduationRange"
			v-model="graduation"
			:title="`Graduación Ojo ${label}`"
		/>
		<GraduationSelector-Select
			:return-object="false"
			:items="['MID', 'LOW', 'HIGH']"
			v-model="add"
			:title="`ADD Ojo ${label}`"
		/>
	</GraduationSelector-Wrapper>
</template>

<style></style>
